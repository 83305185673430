import { useVibrate } from '@vueuse/core'

export default () => {
  const { vibrate: cardDeck } = useVibrate({ pattern: [20] })
  const { vibrate: longPress } = useVibrate({ pattern: [5, 100, 20] })

  const doVibrate = (type, isVibrate) => {
    if (!isVibrate) return
    if (type === 'cardDeck') cardDeck()
    else if (type === 'longPress') longPress()
  }

  return {
    doVibrate,
  }
}
